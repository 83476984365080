import { firestore } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore";

const functionName = "updateStory";

export default async function (
  updatedStoryDocId,
  updatedSharedTo,
  updatedStoryPages,
) {
  console.info(functionName);

  console.debug(
    functionName,
    "docId:",
    updatedStoryDocId,
    "sharedTo:",
    updatedSharedTo,
    "story pages:",
    updatedStoryPages,
  );

  try {
    const docRef = doc(firestore, "stories", updatedStoryDocId);

    await updateDoc(docRef, {
      sharedTo: updatedSharedTo,
      pages: updatedStoryPages,
      updatedAt: new Date().toUTCString(),
    });

    console.debug(functionName, "Story updated successfully");
    return true;
  } catch (error) {
    console.error(functionName, `error: ${error}`);
    return false;
  }
}
