import * as Vue from "vue";

// Firebase
import { firebaseApp } from "@/firebase";

import router from "./router";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";

import { createPinia } from "pinia";

import App from "./App.vue";
import { useSnackBarStore } from "./stores/snackBarStore";
// gEALWAdt -->
import { useNotificationStore } from "./stores/notificationStore";
// <--

document.title = "Storyque";

const app = Vue.createApp(App);
const pinia = createPinia();
const vuetify = createVuetify({
  components,
  directives,
  firebaseApp,
  icons: {
    defaultSet: "mdi",
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#6C757D",
        accent: "#17A2B8",
        error: "#DC3545",
      },
      dark: {
        primary: "#007BFF",
        secondary: "#6C757D",
        accent: "#17A2B8",
        error: "#DC3545",
      },
    },
  },
});

app.use(pinia);
app.use(router);
app.use(vuetify);

app.mount("#app");

// Listen for messages from the service worker
navigator.serviceWorker.addEventListener("message", (event) => {
  console.debug("main", "event: ", event);
  if (event.data && event.data.type) {
    const snackBarStore = useSnackBarStore();
    // gEALWAdt -->
    const notificationStore = useNotificationStore();
    // <--

    const notificationObj = {
      message: event.data.data.data.title,
      url: event.data.data.data.url,
      color: "info",
      timeout: 2500,
      messageType: event.data.data.data.messageType,
    };

    switch (event.data.type) {
      case "displayInAppNotification": {
        console.debug("event.data.type: ", event.data.type);

        notificationStore.readNotificationsFromIDB(notificationObj);
        snackBarStore.displayNotification(notificationObj);
        break;
      }
      case "redirectToStory": {
        console.debug("event.data.type: ", event.data.type);

        const storyId = snackBarStore.extractStoryIdFromUrl(
          event.data.data.url,
        );

        router.push({
          name: "posts",
          params: {
            postId: storyId,
          },
        });

        notificationStore.readNotificationsFromIDB(notificationObj);
        snackBarStore.displayNotification(notificationObj);
        break;
      }
    }
  }
});
