import { deleteDoc } from "firebase/firestore";

export default async function (newStoryRequestDocRef) {
  const functionName = "deleteNewStoryRequest";
  console.info(functionName);

  try {
    await deleteDoc(newStoryRequestDocRef);
    console.log(functionName, "newStoryRequest-document successfully deleted");
    return true;
  } catch (error) {
    console.error(functionName, error);
    return false;
  }
}
