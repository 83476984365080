import { defineStore } from "pinia";
import { ref } from "vue";

const storeName = "nsfwStore";
const nsfwModeKey = "nsfwMode";

export const useNsfwStore = defineStore({
  id: storeName,
  state: () => ({
    initialized: ref(false),
    nsfwMode: ref(null),
  }),
  actions: {
    async onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      this.readNsfwMode();

      console.debug(storeName, functionName, "initialized");
      this.initialized = true;
    },
    readNsfwMode() {
      const functionName = "readNsfwMode";
      console.info(storeName, functionName);

      try {
        const nsfwMode = localStorage.getItem(nsfwModeKey);
        console.debug(storeName, functionName, "nsfwMode:", nsfwMode);
        if (nsfwMode == "null") {
          console.debug(
            storeName,
            functionName,
            "nsfwMode not set, default to 'false'",
          );
          this.nsfwMode = false;
        } else if (nsfwMode == "false") {
          console.debug(storeName, functionName, "nsfwMode set to 'false'");
          this.nsfwMode = false;
        } else if (nsfwMode == "true") {
          console.debug(storeName, functionName, "nsfwMode set to 'true'");
          this.nsfwMode = true;
        }
      } catch (error) {
        console.error(
          storeName,
          functionName,
          "error occurred when reading 'nsfwMode' from localStorage, defaulting to 'false'. Error:",
          error,
        );
        this.nsfwMode = false;
      }
    },
    updateNsfwMode(newNsfwMode) {
      const functionName = "updateNsfwMode";
      console.info(storeName, functionName);

      console.debug(storeName, functionName, "newNsfwMode:", newNsfwMode);

      this.nsfwMode = newNsfwMode;

      try {
        localStorage.setItem(nsfwModeKey, newNsfwMode);
        console.debug(
          storeName,
          functionName,
          `nsfwMode set to '${newNsfwMode}'`,
        );
      } catch (error) {
        console.error(storeName, functionName, "error:", error);
      }
    },
  },
});
