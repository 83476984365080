import { firestore } from "@/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useAuthStore } from "./authStore";
import { useFriendStore } from "./friendStore";
import { useSnackBarStore } from "./snackBarStore";

const storeName = "myStoriesStore";

export const useMyStoriesStore = defineStore({
  id: storeName,
  state: () => ({
    stories: ref([]),
    firestoreListener: null,
    authStore: useAuthStore(),
    friendStore: useFriendStore(),
    snackbarStore: useSnackBarStore(),
    storiesBackupStates: ref([]),
    docsDiffInSharedStatuses: ref([]),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.log(storeName, functionName);

      // Automatically fetch user's own stories on login
      watch(
        () => this.authStore.userId,
        async (newUserId) => {
          console.info(storeName, functionName, "newUser: ", newUserId);
          if (newUserId != null) {
            console.debug(storeName, functionName, "User logged in");
            this.firestoreListenerForMyStories();
            this.watchStoriesIsSharedStatuses();
          } else {
            if (this.stories.length > 0) {
              this.stories = [];
            }
            if (this.storiesBackupStates.length > 0) {
              this.storiesBackupStates = [];
            }

            if (this.docsDiffInSharedStatuses.length > 0) {
              this.docsDiffInSharedStatuses = [];
            }
            if (this.firestoreListener) {
              this.firestoreListener();
              this.firestoreListener = null;
            }
          }
        },
        { immediate: true },
      );
    },
    firestoreListenerForMyStories() {
      const functionName = "firestoreListenerForMyStories";
      console.info(storeName, functionName);

      console.debug(storeName, functionName, "userId:", this.authStore.userId);

      const q = query(
        collection(firestore, "stories"),
        where("creatorId", "==", this.authStore.userId),
      );

      this.firestoreListener = onSnapshot(q, (querySnapshot) => {
        let stories = [];
        console.debug(
          storeName,
          functionName,
          "querySnapshot: ",
          querySnapshot.docs.length,
        );
        querySnapshot.forEach((doc) => {
          console.debug(storeName, functionName, doc.id, "==", doc.data());
          let docData = doc.data();
          if (docData?.sharedTo == undefined) {
            docData.sharedTo = null;
            docData.isShared = false;
          } else if (docData?.sharedTo != null) {
            docData.isShared = true;
          }
          if (docData?.isShared == undefined) {
            docData.isShared = false;
          }
          stories.push({ docId: doc.id, ...docData });
        });
        // Sort stories in descending order (from newest to oldest)
        stories.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.debug(storeName, functionName, "stories: ", stories);
        this.stories = stories.slice();

        // Backup stories with only story ID and isShared-status for later comparison
        let backup = [];
        stories.forEach((story) => {
          const temp = {
            docId: story.docId,
            id: story.id,
            isShared: story.isShared,
          };
          backup.push(temp);
        });
        this.storiesBackupStates = backup.slice();
      });
    },
    watchStoriesIsSharedStatuses() {
      const functionName = "watchStoriesIsSharedStatuses";
      watch(
        () => this.stories,
        (updatedStories) => {
          console.debug(
            "myStoriesStore",
            functionName,
            "updatedStories: ",
            updatedStories,
            "backupStories:",
            this.storiesBackupStates,
          );

          let differingObjects = updatedStories.filter((obj1) => {
            const match = this.storiesBackupStates.find(
              (obj2) => obj1.id == obj2.id,
            );
            return match && obj1.isShared != match.isShared;
          });
          console.debug(
            "myStoriesStore",
            functionName,
            "Differing isShared-status stories: ",
            differingObjects,
          );
          console.debug(
            "myStoriesStore",
            functionName,
            "Differing isShared-status stories count: ",
            differingObjects.length,
          );
          for (let i = 0; i < differingObjects.length; i++) {
            differingObjects[i] = Object.fromEntries(
              Object.entries(differingObjects[i]).filter(([k]) =>
                ["docId", "isShared"].includes(k),
              ),
            );
          }
          this.docsDiffInSharedStatuses = [...differingObjects];
        },
        { deep: true },
      );
    },
    onInactive() {
      if (this.firestoreListener) {
        this.firestoreListener(); // Invoke the Firestore listener function to unsubscribe
        this.firestoreListener = null; // Clear the listener reference
      }
    },
    setStories(stories) {
      this.stories = [...stories];
      console.debug("myStoriesStore", "stories: ", this.stories);
    },
    /**
     *
     * @param {*} storyId
     * @returns story-object that has matching ID
     */
    getStoryById(storyId) {
      const functionName = "getStoryById";
      console.log("myStoriesStore", functionName);

      console.debug("myStoriesStore", functionName, "storyId:", storyId);

      const storyIndex = this.stories.findIndex(
        (story) => story.id === storyId,
      );
      console.debug(
        "myStoriesStore",
        "getStoryById",
        `storyIndex: ${storyIndex}`,
      );
      if (storyIndex == -1) {
        return null;
      } else if (storyIndex >= 0 && storyIndex < this.stories.length) {
        return this.stories[storyIndex];
      }
    },
    getStoryIndexById(storyId) {
      return this.stories.findIndex((story) => story.id === storyId);
    },
    async postStory(storyId, creatorId, title, storyPages) {
      console.log("myStoriesStore", "postStory");
      const docRef = collection(firestore, "stories");

      const story = {
        storyId: storyId,
        creatorId: creatorId,
        title: title,
        pages: [...storyPages],
        private: false,
        sharedTo: [],
      };
      try {
        const doc = await addDoc(docRef, story);
        console.debug("myStoriesStore", `Document created with ID: ${doc.id}`);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async updateStorySharedStatus() {
      const functionName = "updateStorySharedStatus";
      console.log(storeName, functionName);

      let friendId;
      if (
        this.friendStore.acceptedFriendRequest.creatorId !=
        this.authStore.userId
      ) {
        friendId = this.friendStore.acceptedFriendRequest.creatorId;
      } else if (
        this.friendStore.acceptedFriendRequest.receiverId !=
        this.authStore.userId
      ) {
        friendId = this.friendStore.acceptedFriendRequest.receiverId;
      }
      console.debug(storeName, functionName, "friendId: ", friendId);
      try {
        let response = await fetch(
          process?.env?.NODE_ENV === "development" ||
            window?.location?.hostname === "localhost"
            ? "http://localhost:5001/storyque-fee51/us-central1/api/updateStorySharedStatus"
            : "https://api-efmmhglqbq-uc.a.run.app/updateStorySharedStatus",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              stories: this.docsDiffInSharedStatuses,
              friendId: friendId,
            }),
          },
        );
        if (response.ok) {
          response = await response.json();
          console.debug(storeName, functionName, "response: ", response);
          if (!response.status) {
            console.warn(storeName, functionName, "status: ", response.status);
            this.snackbarStore.displayNotification({
              message: response.message,
              color: "error",
            });
          } else {
            this.snackbarStore.displayNotification({
              message: response.message,
              color: "success",
            });
          }
        } else {
          this.snackbarStore.displayNotification({
            message: "Error occurred, please try again later",
            color: "error",
          });
        }
      } catch (error) {
        console.error(
          storeName,
          functionName,
          "Batch stories sharedTo-field update failed: ",
          error,
        );
        this.snackbarStore.displayNotification({
          message: "Failed to update share-status to multiple stories",
          color: "error",
          timeout: 2500,
        });
      }
    },
    cancelStoryShareStatusChanges() {
      this.storiesBackupStates.forEach((aItem) => {
        const bItem = this.stories.find((b) => b.id === aItem.id);
        if (bItem && bItem.isShared != aItem.isShared) {
          bItem.isShared = aItem.isShared;
        }
      });
    },
    async deleteStoryById(storyId) {
      try {
        const q = query(
          collection(firestore, "stories"),
          where("id", "==", storyId),
          limit(1),
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          await deleteDoc(doc.ref);
          console.log(
            "myStoriesStore",
            `Story with ID ${storyId} deleted successfully.`,
          );
          return true;
        } else {
          console.debug("myStoriesStore", `No story found with ID ${storyId}`);
          return false;
        }
      } catch (error) {
        console.error(
          "myStoriesStore",
          "Error occurred when deleting story: ",
          error,
        );
        return false;
      }
    },
  },
});
