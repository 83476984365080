<template>
  <v-app id="storyque">
    <v-app-bar
      v-if="
        [
          'posts',
          'linkDevicesCreate',
          'storyCreator',
          'storyEditor',
          'profile',
          'privateSharedPosts',
        ].includes(route.name) && authStore.user
      "
      app
      :elevation="2"
      rounded
      density="compact"
    >
      <template v-slot:prepend>
        <v-app-bar-nav-icon
          v-if="
            [
              'posts',
              'linkDevicesCreate',
              'storyCreator',
              'storyEditor',
              'privateSharedPosts',
            ].includes(route.name)
          "
          @click="
            () => {
              if (route.name == 'storyEditor' && editStoryStore.isStoryEdited) {
                discardChangesDialog = true;
              } else if (
                route.name == 'storyCreator' &&
                newStoryStore.pendingNewStory()
              ) {
                discardChangesDialog = true;
              } else {
                goBack();
              }
            }
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-title
          v-if="!authStore.user || authStore.title"
          style="font-weight: bold"
          >Storyque</v-app-bar-title
        >
      </template>
      <template v-slot:append>
        <!-- [start] /posts right-side elements -->
        <template v-if="route.name == 'posts'">
          <template v-if="!appBarStore.contentCreatorName">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </template>
          <template v-else>
            <v-app-bar-nav-icon style="pointer-events: none">
              <v-icon>mdi-account</v-icon>
            </v-app-bar-nav-icon>
            <v-app-bar-title
              :style="{
                fontWeight:
                  appBarStore?.contentCreatorName === authStore?.username
                    ? 'bold'
                    : 'normal',
              }"
              >{{
                appBarStore?.contentCreatorName === authStore?.username
                  ? "You"
                  : appBarStore?.contentCreatorName
              }}</v-app-bar-title
            >
          </template>
        </template>
        <!-- [end] /posts right-side elements -->
        <!-- [start] /profile right-side elements -->
        <template
          v-else-if="['profile', 'privateSharedPosts'].includes(route.name)"
        >
          <!-- [start] ZHhPKpfo: NSFW-mode -->
          <v-btn
            variant="tonal"
            size="small"
            :style="{
              color: 'transparent',
              borderRadius: '15px',
              marginRight: '5px',
              backgroundColor: '',
            }"
            @click="toggleNsfwMode"
          >
            <v-icon :color="primaryColor" size="x-large">{{
              nsfwStore.nsfwMode ? "mdi-eye" : "mdi-eye-off"
            }}</v-icon>
          </v-btn>
          <!-- [end] ZHhPKpfo -->
          <!-- [start] 9qzWh3BW: PWA installation button -->
          <v-btn
            v-if="
              pwaStore.initialized &&
              pwaStore.deferredPrompt &&
              pwaStore.pwaInstallable &&
              route.name == 'profile'
            "
            variant="tonal"
            size="small"
            :style="{
              color: 'transparent',
              borderRadius: '15px',
              marginRight: '5px',
              backgroundColor: '',
            }"
            @click="pwaStore.installPWA"
          >
            <v-icon :color="primaryColor" size="x-large">mdi-download</v-icon>
          </v-btn>
          <!-- [start] Friend request button -->
          <div v-if="route.name == 'profile'">
            <v-menu transition="slide-y-transition">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="tonal"
                  size="small"
                  :style="{
                    color: 'transparent',
                    borderRadius: '15px',
                    marginRight: '5px',
                    backgroundColor: '',
                  }"
                  :disabled="unclearedNotificationsCount == 0"
                >
                  <v-badge
                    color="error"
                    :model-value="unclearedNotificationsCount != 0"
                    :content="unclearedNotificationsCount"
                    rounded="rounded-circle"
                  >
                    <v-icon :color="primaryColor" size="x-large"
                      >mdi-bell</v-icon
                    >
                  </v-badge>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="notification in notificationStore.notifications"
                  :key="notification.id"
                  @click="handleNotificationsMenuRedirect(notification)"
                >
                  <v-list-item-title style="text-align: left">
                    <p style="font-size: large">
                      {{
                        notification?.title
                          ? notification.title
                          : notification?.data?.title
                      }}
                    </p>
                    <p style="font-size: smaller">
                      {{
                        notification?.receivedAt
                          ? getTimeSince(notification.receivedAt)
                          : ""
                      }}
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div
            v-if="route.name == 'profile'"
            @mouseover="handleMouseOverFriendRequestButton(true)"
            @mouseleave="handleMouseOverFriendRequestButton(false)"
          >
            <v-btn
              :disabled="
                friendStore.acceptedFriendRequestDocRef ||
                friendStore.isProcessingResponse
              "
              variant="tonal"
              size="small"
              @click="
                () => {
                  friendRequestDialog = true;
                }
              "
              :style="{
                color: primaryColor,
                backgroundColor: friendButtonBackgroundColor,
                borderRadius: '15px',
              }"
            >
              <v-progress-circular
                indeterminate
                v-if="friendStore.isProcessingResponse"
                size="20"
                width="3"
              ></v-progress-circular>
              <v-icon
                v-else
                size="large"
                :color="
                  friendButtonHover && friendStore.isPendingFriendRequest
                    ? 'black'
                    : ''
                "
                :icon="friendButtonIcon"
              />
            </v-btn>
          </div>
          <!-- [end] Friend request button -->
          <!-- [start] Settings button -->
          <div
            class="d-flex align-center"
            style="margin-left: 5px"
            v-if="route.name == 'profile'"
          >
            <v-app-bar-nav-icon
              color="primary"
              :disabled="friendStore.isProcessingResponse"
            >
              <v-progress-circular
                indeterminate=""
                v-if="friendStore.isProcessingResponse"
                size="20"
                width="3"
              ></v-progress-circular>
              <v-icon v-else>mdi-cog</v-icon>
              <v-menu
                activator="parent"
                :disabled="
                  friendStore.isProcessingResponse || processingUnlinkDevices
                "
              >
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                      :style="{
                        color: `${
                          devicesStore.linkedDevicesDocs.length < 5
                            ? primary
                            : 'grey'
                        }`,
                        cursor: `${
                          devicesStore.linkedDevicesDocs.length < 5
                            ? 'pointer'
                            : 'not-allowed'
                        }`,
                        pointerEvents: `${
                          devicesStore.linkedDevicesDocs.length < 5
                            ? 'all'
                            : 'none'
                        }`,
                      }"
                      @click="linkDevices"
                      class="py-2"
                      >Link devices</v-list-item-title
                    >
                    <v-list-item-title
                      style="color: red; cursor: pointer"
                      @click="
                        () => {
                          if (!devicesStore.emailVerified) {
                            // Need to verify email first to get OTP/code for next login
                            emailVerificationDialog = true;
                          } else {
                            unlinkAllDevicesDialog = true;
                          }
                        }
                      "
                      class="py-2"
                      >Unlink devices</v-list-item-title
                    >
                    <v-list-item-title
                      :style="{
                        color: `${
                          friendStore.acceptedFriendRequest != null
                            ? 'red'
                            : 'grey'
                        }`,
                        cursor: `${
                          friendStore.acceptedFriendRequest != null
                            ? 'pointer'
                            : 'not-allowed'
                        }`,
                        pointerEvents: `${
                          friendStore.acceptedFriendRequest != null
                            ? 'all'
                            : 'none'
                        }`,
                      }"
                      @click="unfriend"
                      class="py-2"
                      >Unfriend</v-list-item-title
                    >
                    <v-list-item-title
                      style="color: red; cursor: pointer"
                      @click="logout"
                      class="py-2"
                      >Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar-nav-icon>
          </div>
          <!-- [end] Settings button -->
          <!-- [end] /profile right-side elements -->
        </template>
        <template
          v-else-if="['storyCreator', 'storyEditor'].includes(route.name)"
        >
          <!-- [start] StoryCreatorView send-button -->
          <div class="d-flex align-center">
            <div>
              <v-app-bar-nav-icon
                color="primary"
                :disabled="!isStoryPostable || isPosting"
                @click="
                  () => {
                    if (route.name == 'storyEditor') {
                      editStoryStore.updateStory();
                      goBack();
                    } else {
                      newStoryStore.postStory();
                    }
                  }
                "
              >
                <v-icon v-if="isPosting == false">{{
                  route.name == "storyEditor"
                    ? "mdi-content-save-edit"
                    : "mdi-send"
                }}</v-icon>
                <v-progress-circular v-else color="primary" indeterminate />
              </v-app-bar-nav-icon>
            </div>
          </div>
          <!-- [end] StoryCreatorView send-button -->
        </template>
      </template>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <template
          v-if="loadingScreenStore.isLoading == false && authStore.initialized"
        >
          <router-view />
        </template>
        <template v-else>
          <LoadingScreen />
        </template>
      </v-container>
      <!-- [start] Notification permission dialog -->
      <v-dialog
        max-width="500"
        v-model="notificationPermissionDialog"
        transition="dialog-top-transition"
      >
        <template v-slot:default="{ isActive }">
          <v-card title="Notifications">
            <v-card-text>
              <p style="display: inline">
                Grant Storyque notification permissions?
              </p>

              <p
                v-if="fcmStore.notificationPermissionGranted == false"
                style="white-space: pre-wrap"
                v-html="getNotificationPermission()"
              ></p>
            </v-card-text>

            <v-card-actions>
              <!-- User has not yet decided -->
              <template
                v-if="fcmStore.notificationPermissionGranted == undefined"
              >
                <v-btn
                  color="primary"
                  variant="tonal"
                  @click="
                    async () => {
                      await fcmStore.requestNotificationPermission();
                      isActive.value = false;
                      notificationStore.removeNotification(
                        'requestNotificationPermission',
                      );
                    }
                  "
                  ><v-icon icon="mdi-check"></v-icon
                ></v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text="Close"
                  variant="text"
                  color="error"
                  @click="isActive.value = false"
                  ><v-icon icon="mdi-close"></v-icon
                ></v-btn>
              </template>
              <!-- User has previously rejected -->
              <template v-if="fcmStore.notificationPermissionGranted == false">
                <v-spacer></v-spacer>
                <v-btn
                  text="OK"
                  variant="tonal"
                  color="primary"
                  @click="isActive.value = false"
                  ><v-icon icon="mdi-check"></v-icon
                ></v-btn>
                <v-spacer></v-spacer>
              </template>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <!-- [end] Notification permission dialog -->
      <!-- [start] xqxuuUvs -->
      <v-dialog
        max-width="500"
        v-model="emailVerificationDialog"
        transition="dialog-fade-transition"
      >
        <template v-slot:default="">
          <v-card title="Email verification">
            <v-tabs v-model="emailVerificationTabs" fixed-tabs>
              <v-tab value="email" readonly>Email</v-tab>
              <v-tab value="code" readonly>Code</v-tab>
            </v-tabs>
            <v-card-text>
              <v-tabs-window v-model="emailVerificationTabs">
                <v-tabs-window-item value="email">
                  <v-text-field
                    v-model="emailInput"
                    width="95%"
                    clearable
                    label="Email"
                    prepend-icon="mdi-email-outline"
                    :rules="[validateEmail]"
                  ></v-text-field>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 5px;
                    "
                  >
                    <v-btn
                      :disabled="
                        emailInput?.length == 0 ||
                        emailInputErrors?.length > 0 ||
                        processingSendingEmail
                      "
                      @click="
                        async () => {
                          if (!devicesStore.emailVerified) {
                            await handleVerificationCodeRequest();
                          }
                        }
                      "
                      color="primary"
                      variant="tonal"
                    >
                      <v-progress-circular
                        v-if="processingSendingEmail"
                        indeterminate
                        size="20"
                        width="3"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-send"></v-icon>
                    </v-btn>
                  </div>
                </v-tabs-window-item>

                <v-tabs-window-item value="code"
                  ><v-otp-input
                    v-model="verificationCode"
                    :loading="processingSendingVerificationCode"
                    length="6"
                    variant="outlined"
                  ></v-otp-input>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 5px;
                    "
                  >
                    <v-btn
                      :disabled="
                        verificationCode.length < 6 ||
                        processingSendingVerificationCode
                      "
                      @click="
                        async () => {
                          if (await handleVerificationCodeVerification()) {
                            emailVerificationDialog = false;
                            await unlinkAllDevices();
                          }
                        }
                      "
                      color="primary"
                      variant="tonal"
                    >
                      <v-progress-circular
                        v-if="processingSendingVerificationCode"
                        indeterminate
                        size="20"
                        width="3"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-check"></v-icon>
                    </v-btn>
                  </div>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog
        max-width="500"
        v-model="unlinkAllDevicesDialog"
        transition="dialog-fade-transition"
        ><template v-slot:default="{ isActive }">
          <v-card title="Unlink all devices">
            <v-card-text
              >You sure you want to unlink all devices from your
              account?</v-card-text
            >
            <v-card-actions>
              <v-btn color="primary" variant="tonal" @click="unlinkAllDevices"
                ><v-icon>mdi-check</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isActive.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card></template
        >
      </v-dialog>
      <!-- [end] xqxuuUvs -->
      <!-- [start] cG0SaCif -->
      <v-dialog
        max-width="500"
        v-model="friendRequestDialog"
        transition="dialog-fade-transition"
        ><template v-slot:default="{ isActive }">
          <v-card
            title="Friend request"
            :prepend-icon="
              friendStore.isPendingFriendRequest
                ? 'mdi-account-cancel'
                : 'mdi-account-plus'
            "
          >
            <v-card-text>{{ getFriendRequestDialogText() }}</v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                variant="tonal"
                @click="
                  () => {
                    friendStore.isPendingFriendRequest
                      ? friendStore.deleteFriendRequest()
                      : friendStore.createFriendRequest();
                    isActive.value = false;
                  }
                "
                ><v-icon>mdi-check</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isActive.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card></template
        >
      </v-dialog>
      <!-- [end] cG0SaCif -->
      <!-- [start] HCmYXvYI -->
      <v-dialog
        max-width="500"
        v-model="discardChangesDialog"
        transition="dialog-fade-transition"
        ><template v-slot:default="{ isActive }">
          <v-card title="Discard changes" prepend-icon="mdi-pencil-remove">
            <v-card-text>Discard changes and return to frontpage?</v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                variant="tonal"
                @click="
                  () => {
                    isActive.value = false;
                    router.replace('/');
                  }
                "
                ><v-icon>mdi-check</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isActive.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card></template
        >
      </v-dialog>
      <!-- [end] HCmYXvYI -->
    </v-main>
    <BottomNavigation />
    <SnackBar />
  </v-app>
</template>

<script>
import { getPreviousRoute } from "@/router";
import { usePwaStore } from "@/stores/pwaStore";
import { onMounted, onUnmounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BottomNavigation from "./components/BottomNavigation.vue";
import SnackBar from "./components/SnackBar.vue";
import { useAppBarStore } from "./stores/appBarStore";
import { useAuthStore } from "./stores/authStore";
import { useCurrentViewedStoryStore } from "./stores/currentViewedStoryStore";
import { useDevicesStore } from "./stores/devicesStore";
import { useDimenStore } from "./stores/dimenStore";
import { useFcmStore } from "./stores/fcmStore";
import { useFriendStore } from "./stores/friendStore";
import { useLoadingScreenStore } from "./stores/loadingScreenStore";
import { useMyStoriesStore } from "./stores/myStoriesStore";
import { useNewStoryStore } from "./stores/newStoryStore";
import { useSnackBarStore } from "./stores/snackBarStore";
import { useVisibilityStore } from "./stores/visibilityStore";
import LoadingScreen from "./views/LoadingScreen.vue";
import { useTheme } from "vuetify";
// xqxuuUvs -->
import checkVerificationCode from "./utils/access/checkVerificationCode";
import deleteUserCredentials from "./utils/access/deleteUserCredentials";
import getVerificationCode from "./utils/access/getVerificationCode";
// <--
// HyRaXhPy -->
import getNotificationPermission from "@/utils/getNotificationPermissionEnableGuide";
// <--
// gEALWAdt -->
import { useNotificationStore } from "./stores/notificationStore";
import getTimeSince from "@/utils/getTimeSince";
// <--
import { useStoriesSharedWithYou } from "./stores/storiesSharedWithYouStore";
// HCmYXvYI -->
import { useEditStoryStore } from "./stores/editStoryStore";
// <--
// ZHhPKpfo -->
import { useNsfwStore } from "./stores/nsfwStore";
// <--

const componentName = "App";

export default {
  name: componentName,
  components: {
    LoadingScreen,
    SnackBar,
    BottomNavigation,
  },
  setup() {
    console.debug(componentName, "setup");

    const router = useRouter();
    const route = useRoute();

    const friendStore = useFriendStore();
    friendStore.onInit();

    // gEALWAdt -->
    const notificationStore = useNotificationStore();
    notificationStore.onInit();

    // cG0SaCif -->
    const friendRequestDialog = ref(false);

    const getFriendRequestDialogText = () => {
      if (friendStore?.isPendingFriendRequest) {
        return "You have a pending friend request, do you want to cancel it?";
      } else {
        return "Create new friend request?";
      }
    };
    // <-- cG0SaCif

    // X06H8yxx -->
    const storiesSharedWithYouStore = useStoriesSharedWithYou();
    storiesSharedWithYouStore.onInit();
    // <--

    // xqxuuUvs -->
    const devicesStore = useDevicesStore();
    devicesStore.onInit();

    // In case user has not yet verified their email address
    const emailVerificationDialog = ref(false);
    const emailVerificationTabs = ref(null);

    // In case user has verified their email address, prompt to unlink all devices for user account
    const unlinkAllDevicesDialog = ref(false);

    const emailInput = ref("");
    const emailInputErrors = ref([]);

    const processingEmail = ref(false);

    watch(
      () => emailVerificationDialog.value,
      (newEmailVerificationDialogValue) => {
        if (!newEmailVerificationDialogValue) {
          // Reset email verification dialog when it's closed
          processingEmail.value = false;
          emailVerificationTabs.value = "email";
          emailInput.value = "";
          verificationCodeInput.value = "";
          processingVerificationCode.value = false;
        }
      },
      { immediate: false, deep: false },
    );

    const verificationCodeInput = ref("");
    const processingVerificationCode = ref(false);

    const processingUnlinkDevices = ref(false);
    // <-- xqxuuUvs

    // 9qzWh3BW: Prevent automatic PWA install prompt -->
    const pwaStore = usePwaStore();
    pwaStore.onInit();
    // <--

    // ZHhPKpfo -->
    const nsfwStore = useNsfwStore();
    nsfwStore.onInit();
    // <--

    // Pinia stores -->
    const newStoryStore = useNewStoryStore();

    const currentViewedStoryStore = useCurrentViewedStoryStore();

    const appBarStore = useAppBarStore();

    const loadingScreenStore = useLoadingScreenStore();
    loadingScreenStore.onInit();

    const authStore = useAuthStore();
    authStore.onInit();

    const snackBarStore = useSnackBarStore();
    snackBarStore.onInit();

    const visibilityStore = useVisibilityStore();
    visibilityStore.onInit();

    const fcmStore = useFcmStore();
    fcmStore.onInit();

    const myStoriesStore = useMyStoriesStore();
    myStoriesStore.onInit();

    const dimenStore = useDimenStore();
    // <--

    const containerHeight = ref(null);

    // Notification permission button -->
    const notificationPermissionDialog = ref(false);
    // <--

    // 3X6Dpqut -->
    const notificationPermissionTriggeredByUser = ref(false);
    // <--

    // HCmYXvYI (& Tz42pjbq) -->
    const discardChangesDialog = ref(false);
    const editStoryStore = useEditStoryStore();

    const isStoryPostable = computed(() => {
      if (route.name == "storyEditor") {
        return editStoryStore.isStoryEdited && editStoryStore.validStory;
      } else {
        return newStoryStore.validStory;
      }
    });

    const isPosting = computed(() => {
      if (editStoryStore.editMode) {
        return editStoryStore.isPosting;
      } else {
        return newStoryStore.posting;
      }
    });

    // Friend request button -->
    const vTheme = useTheme();
    const primaryColor = vTheme.current.value.colors.primary;
    const successColor = vTheme.current.value.colors.success + "80";
    const errorColor = vTheme.current.value.colors.error + "80";

    const friendButtonIcon = ref(null);
    const friendButtonBackgroundColor = ref("");
    const friendButtonHover = ref(false);

    watch(
      () => [
        friendStore.acceptedFriendRequest,
        friendStore.isPendingFriendRequest,
        friendStore.isProcessingResponse,
        friendButtonHover.value,
      ],
      ([
        newAcceptedFriendRequest,
        newIsPendingFriendRequest,
        newIsProcessingResponse,
        newFriendButtonHover,
      ]) => {
        console.debug(
          componentName,
          "newAcceptedFriendRequest:",
          newAcceptedFriendRequest,
          "newIsPendingFriendRequest:",
          newIsPendingFriendRequest,
          "newIsProcessingResponse:",
          newIsProcessingResponse,
          "newFriendButtonHover:",
          newFriendButtonHover,
        );

        if (newFriendButtonHover) {
          if (!newIsProcessingResponse) {
            if (newIsPendingFriendRequest) {
              friendButtonBackgroundColor.value = errorColor;
              friendButtonIcon.value = "mdi-account-remove";
            } else {
              friendButtonBackgroundColor.value = "";
              friendButtonIcon.value = "mdi-account-plus";
            }
          }
        } else {
          if (!newIsProcessingResponse) {
            if (newIsPendingFriendRequest) {
              friendButtonBackgroundColor.value = "";
              friendButtonIcon.value = "mdi-account-clock";
            } else {
              friendButtonBackgroundColor.value = "";
              friendButtonIcon.value = "mdi-account-plus";
            }
          }
        }
      },
      { immediate: true, deep: false },
    );

    const handleMouseOverFriendRequestButton = (isHovering) => {
      friendButtonHover.value = isHovering;
    };
    // <--

    // Settings-cog -->
    const linkDevices = () => {
      router.push("/linkDevices/create");
    };

    const logout = async () => {
      console.debug("appBar", "logout");
      authStore.logout();
    };
    // <--

    const goBack = () => {
      const functionName = "goBack";
      console.info(componentName, functionName);
      console.debug(
        componentName,
        functionName,
        "Previous route: ",
        getPreviousRoute(),
      );

      currentViewedStoryStore.unsetStory();
      router.replace("/home");
    };

    // xqxuuUvs -->
    const resetEmailVerificationDialog = () => {
      emailVerificationDialog.value = false;
      emailInput.value = "";
      emailInputErrors.value = [];
      processingEmail.value = false;
      verificationCodeInput.value = "";
      processingVerificationCode.value = false;
    };

    const validateEmail = (value) => {
      const functionName = "validateEmail";
      console.info(componentName, functionName);
      console.debug(componentName, functionName, "value:", value);

      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      emailInputErrors.value = re.test(value.toLowerCase())
        ? []
        : ["Invalid email address"];
      console.debug(
        componentName,
        functionName,
        "regex result:",
        emailInputErrors.value,
      );

      if (emailInputErrors.value.length > 0) {
        return emailInputErrors.value[0];
      } else {
        return true;
      }
    };

    const handleVerificationCodeRequest = async () => {
      const functionName = "handleVerificationCodeRequest";
      console.info(componentName, functionName);

      emailVerificationDialog.value = true;

      processingEmail.value = true;

      const success = await getVerificationCode(
        authStore.userId,
        emailInput.value,
      );

      processingEmail.value = false;

      console.debug(componentName, functionName, "success:", success);

      if (success) {
        // Switch from email input to code input on successful code creation
        emailVerificationTabs.value = "code";
      }
    };

    const handleVerificationCodeVerification = async () => {
      const functionName = "handleVerificationCodeVerification";
      console.info(componentName, functionName);

      processingVerificationCode.value = true;

      const response = await checkVerificationCode(
        authStore.userId,
        emailInput.value,
        verificationCodeInput.value,
      );

      processingVerificationCode.value = false;

      console.debug(componentName, functionName, "response:", response);

      if (response.status == 200) {
        resetEmailVerificationDialog();
        snackBarStore.displayNotification({
          message: "Email successfully verified",
          color: "success",
          timeout: 2000,
        });
      } else if (response.status == 404) {
        snackBarStore.displayNotification({
          message: "Invalid code",
          color: "error",
          timeout: 2000,
        });
      } else {
        snackBarStore.displayNotification({
          message: "Error occurred when verifying code",
          color: "error",
          timeout: 2000,
        });
      }
    };

    const unlinkAllDevices = async () => {
      const functionName = "unlinkAllDevices";
      console.info(componentName, functionName);

      processingUnlinkDevices.value = true;

      const result = await deleteUserCredentials();

      processingUnlinkDevices.value = false;

      if (result) {
        snackBarStore.displayNotification({
          message: "Devices successfully unlinked from account",
          color: "success",
          timeout: 2000,
        });
        authStore.logout();
      } else {
        snackBarStore.displayNotification({
          message: "Error occurred when unlinking devices from account",
          color: "error",
          timeout: 2000,
        });
      }

      unlinkAllDevicesDialog.value = false;
    };
    // <-- xqxuuUvs

    const handleNotificationsMenuRedirect = async (notification) => {
      if (notification?.notificationType) {
        switch (notification.notificationType) {
          case "requestNotificationPermission":
            notificationPermissionDialog.value = true;
            break;
        }
      } else if (notification?.data?.url) {
        await notificationStore.removeIdNotification(notification.id);
        const url = new URL(notification.data.url);
        router.push({ path: url.pathname });
      }
    };

    const unclearedNotificationsCount = computed(() => {
      return notificationStore.notifications.length;
    });

    // ZHhPKpfo -->¨
    const toggleNsfwMode = () => {
      nsfwStore.updateNsfwMode(nsfwStore.nsfwMode ? false : true);
    };
    // <--

    onMounted(() => {
      // Listen for the appinstalled event
      window.addEventListener("appinstalled", pwaStore.handleAppInstalled);
      // <-- 9qzWh3BW

      window.addEventListener("resize", () => {
        dimenStore.calculateIsMobile();
      });

      fcmStore.getNotificationPermission();
    });

    onUnmounted(() => {
      // 9qzWh3BW -->
      window.removeEventListener("appinstalled", pwaStore.handleAppInstalled);
      // <-- 9qzWh3BW

      window.removeEventListener("resize", () => {
        dimenStore.calculateIsMobile();
      });
    });

    return {
      containerHeight,
      // Pinia stores
      authStore,
      dimenStore,
      loadingScreenStore,
      appBarStore,
      friendStore,
      // v-app-bar back button
      goBack,
      // v-app-bar Friend request button
      handleMouseOverFriendRequestButton,
      friendButtonBackgroundColor,
      friendButtonHover,
      friendButtonIcon,
      primaryColor,
      successColor,
      errorColor,
      route,
      // v-app-bar Settings-cog
      linkDevices,
      unfriend: friendStore.unfriend,
      logout,
      // v-app-bar story send -button
      newStoryStore,
      // Notification permission
      fcmStore,
      notificationPermissionDialog,
      // 9qzWh3BW
      pwaStore,
      // xqxuuUvs
      devicesStore,
      emailVerificationDialog,
      emailVerificationTabs,
      emailInput,
      emailInputErrors,
      handleVerificationCodeRequest,
      validateEmail,
      processingSendingEmail: processingEmail,
      verificationCode: verificationCodeInput,
      processingSendingVerificationCode: processingVerificationCode,
      handleVerificationCodeVerification,
      unlinkAllDevices,
      processingUnlinkDevices,
      resetEmailVerificationDialog,
      unlinkAllDevicesDialog,
      // cG0SaCif
      friendRequestDialog,
      getFriendRequestDialogText,
      // 3X6Dpqut
      unclearedNotificationsCount,
      notificationPermissionTriggeredByUser,
      // HyRaXhPy
      getNotificationPermission,
      // gEALWAdt
      notificationStore,
      handleNotificationsMenuRedirect,
      getTimeSince,
      // HCmYXvYI (& Tz42pjbq)
      editStoryStore,
      isStoryPostable,
      isPosting,
      router,
      discardChangesDialog,
      // ZHhPKpfo
      nsfwStore,
      toggleNsfwMode,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  /* height: 100vh; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

html,
body {
  overflow: hidden;
  /* height: 100vh; */
  width: 100vw;
  /* position: fixed; */
}

.v-container {
  /* height: 100dvh !important; */
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.v-responsive__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Settings-cog --> */
.v-list-item-title {
  text-align: center;
}

.v-list-item-title:hover {
  background-color: #e9ecefbf;
  border-radius: 10px;
}

.disabled-title {
  pointer-events: none; /* Disable clicking */
  user-select: none; /* Disable highlighting */
}
/* <-- */
</style>
