import { useAuthStore } from "@/stores/authStore";
import { useFriendStore } from "@/stores/friendStore";

const functionName = "requestStoryFromFriend";

export default async function () {
  console.info(functionName);

  const authStore = useAuthStore();
  const friendStore = useFriendStore();

  try {
    const apiUrl = `http${
      process?.env?.NODE_ENV === "production" ? "s" : ""
    }://${
      process?.env?.NODE_ENV === "production"
        ? "api-efmmhglqbq-uc.a.run.app/"
        : "localhost:5001/storyque-fee51/us-central1/api/"
    }requestStory`;

    const body = {
      requesterUserId: authStore.userId,
      requesterUsername: authStore.username,
      requestedUserId: friendStore.getFriendUserId(),
      requestedUsername: friendStore.getFriendUsername(),
    };
    console.debug(functionName, `body: ${body}`);

    let response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Specify that you are sending JSON data
      },
      body: JSON.stringify(body),
    });
    return { status: response.status, statusText: response.statusText };
  } catch (error) {
    console.error(functionName, "Error:", error);
    return false;
  }
}
