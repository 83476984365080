/**
 * sTVYVoEe
 *
 * Get story-documents that are being shared with the user by the user's current friend
 */

import { useAuthStore } from "./authStore";
import { useFriendStore } from "./friendStore";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "@/firebase";

const storeName = "storiesSharedWithYouStore";

export const useStoriesSharedWithYou = defineStore({
  id: storeName,
  state: () => ({
    stories: ref([]),
    queryArray: ref(null),
    authStore: ref(useAuthStore()),
    friendStore: ref(useFriendStore()),
    storiesSharedWithYouSnapshotListener: ref(null),
    // X06H8yxx -->
    newStoryRequestSnapshotListener: ref(null),
    allowNewStoryRequest: ref(null),
    newStoryRequestDocRef: ref(null),
    // <--
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      watch(
        [
          () => this.authStore.user,
          () => this.friendStore.acceptedFriendRequest,
        ],
        ([newUser, newAcceptedFriendRequest]) => {
          console.debug(
            storeName,
            functionName,
            "newUser:",
            newUser,
            "newAcceptedFriendRequest:",
            newAcceptedFriendRequest,
          );

          if (
            !this.authStore.userId ||
            !this.friendStore?.acceptedFriendRequest
          ) {
            return;
          }

          const userId = this.authStore.userId;
          const storyCreatorId = this.friendStore.getFriendUserId();

          if (userId && storyCreatorId) {
            console.debug(
              storeName,
              functionName,
              "newUser and friend are not null",
            );
            this.queryArray = [storyCreatorId, userId];
            this.createStoriesSharedWithYouSnapshotListener();
            this.createNewStoryRequestSnapshotListener();
          } else {
            if (this.storiesSharedWithYouSnapshotListener) {
              this.storiesSharedWithYouSnapshotListener = null;
            }

            if (this.queryArray != []) {
              this.queryArray = [];
            }
          }
        },
        { immediate: true },
      );
    },
    /**
     * Get stories that are shared with you, these story-documents have sharedTo-arrayfield
     * that contains first the story creator's userId and then the friend's userId:
     * ...
     * sharedTo: ["creatorId", "friendId"]
     * ...
     */
    async createStoriesSharedWithYouSnapshotListener() {
      const functionName = "createStoriesSharedWithYouSnapshotListener";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, "stories"),
        where("sharedTo", "==", this.queryArray),
      );

      this.storiesSharedWithYouSnapshotListener = onSnapshot(
        q,
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.debug(
              storeName,
              functionName,
              "No stories shared with user",
            );
          } else {
            console.debug(storeName, functionName, "Stories shared with user");
          }

          let docs = [];

          querySnapshot.docs.forEach((doc) => {
            const docData = doc.data();
            docs.push(docData);
          });

          this.stories = [...docs];
        },
      );
    },
    async createNewStoryRequestSnapshotListener() {
      const functionName = "createNewStoryRequestSnapshotListener";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, "newStoryRequests"),
        where("userIds", "array-contains", this.authStore.userId),
        limit(1),
      );

      this.newStoryRequestSnapshotListener = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.debug(
            storeName,
            functionName,
            "No pending new story request created by the user",
          );
          this.allowNewStoryRequest = true;
        } else {
          console.debug(
            storeName,
            functionName,
            "User has created a new story request which is pending",
          );
          this.newStoryRequestDocRef = querySnapshot.docs[0].ref;
          this.allowNewStoryRequest = false;
        }
      });
    },
    getStoryById(storyId) {
      const functionName = "getStoryById";
      console.log(storeName, functionName);
      const storyIndex = this.stories.findIndex(
        (story) => story.id === storyId,
      );
      console.debug(storeName, functionName, `storyIndex: ${storyIndex}`);
      if (storyIndex == -1) {
        return null;
      } else if (storyIndex >= 0 && storyIndex < this.stories.length) {
        return this.stories[storyIndex];
      }
    },
  },
});
