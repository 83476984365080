import isIOS from "./isIOS";
import isChrome from "./isChrome";
import isWindows10 from "./isWindows10";

export default function () {
  const guideBase = "<p style='white-space: pre-wrap; text-align: left;'>";
  let guide = guideBase;
  if (isIOS()) {
    guide =
      "<br><strong>iOS</strong><br><br>1. Go to Settings-app<br>2. Click <i>Notifications</i><br>3. Click <i>Storyque</i><br>4. Click toggle to enable notifications for Storyque";
  } else if (isChrome()) {
    // source: https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop
    guide =
      "<br><strong>Chrome</strong><br><br>1. Click 3-dot button on the top-right<br>2. Click <i>Privacy and security</i><br>3. Click <i>Site Settings</i> <br>4. Click <i>Notifications</i><br>5. Find and click <i>www.storyque.app</i><br>6. Find <i>Notifications</i> and select <i>Allow</i> from the dropdown menu";
  }

  // System-level notification permissions for Windows 10/11
  if (isWindows10()) {
    if (guide != guideBase) {
      guide += "<br><br>";
    }
    guide +=
      "<strong>Windows</strong><br><br>1. Press Windows-key or click Windows-button in taskbar<br>2. Type <i>Settings</i> in the search bar and select it<br>3. Click <i>System</i><br>4. Click <i>Notifications</i><br>" +
      "5. Click <i>Notifications</i>-toggle to enable notifications on your system<br>6. Find <i>Google Chrome</i> or the browser which you used to install the app and verify that the circle in the toggle is on the right side";
  }

  guide += "</p>";

  return guide;
}
