import { client } from "@passwordless-id/webauthn";

async function webAuthnCreateCredential(challenge, userId, username) {
  console.log("createCredential");
  console.debug(
    "createCredential",
    "challenge: ",
    challenge,
    ", userId: ",
    userId,
    ", username: ",
    username,
  );

  if (!client.isAvailable()) {
    console.warn("webAuthnCreateCredential", "WebAuthn protocol not available");
    return { status: false, message: "WebAuthn protocol not available" };
  }

  const isLocalAuthenticator = await client.isLocalAuthenticator();
  if (!isLocalAuthenticator) {
    console.warn(
      "webAuthnCreateCredential",
      "Device can't be used as authenticator",
    );
    return { status: false, message: "Device can't be used as authenticator" };
  }

  try {
    const registration = await client.register(username, challenge, {
      authenticatorType: "auto",
      userVerification: "required",
      timeout: 60000,
      attestation: false,
      userHandle: userId,
      debug: true,
    });

    console.debug("createCredential", "registration", registration);
    return {
      status: true,
      registration: registration,
      message: "Registration successful",
    };
  } catch (error) {
    console.error("createCredential", "error: ", error);
    return {
      status: false,
      registration: null,
      message: "Error occurred when registering",
      info: error,
    };
  }
}

export default webAuthnCreateCredential;
