import { defineStore } from "pinia";
import { ref } from "vue";

const storeName = "snackBarStore";

export const useSnackBarStore = defineStore({
  id: storeName,
  state: () => ({
    snackbar: ref(false),
    timeout: ref(2500),
    message: ref(null),
    messageType: ref(null),
    url: ref(null),
    color: ref("success"),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);
    },
    dismiss() {
      const functionName = "dismiss";
      console.info(storeName, functionName);
      this.resetSnackbar();
    },
    resetSnackbar() {
      const functionName = "resetSnackbar";
      console.info(storeName, functionName);
      this.snackbar = false;
      this.timeout = 2500;
      this.message = null;
      this.messageType = null;
      this.url = null;
      this.color = "success";
    },
    displayNotification(payload) {
      const functionName = "displayNotification";
      this.resetSnackbar();
      console.info(storeName, functionName);
      console.debug(storeName, functionName, "payload: ", payload);
      this.timeout = payload?.timeout ? payload.timeout : this.timeout;
      this.message = payload?.message ? payload.message : this.message;
      this.messageType = payload?.messageType ? payload.messageType : null;
      this.url = payload?.url ? payload.url : this.url;
      this.color = payload?.color ? payload.color : this.color;
      this.snackbar = true;
    },
    extractStoryIdFromUrl(url) {
      const functionName = "extractStoryIdFromUrl";
      console.info(functionName);
      const lastIndex = url.lastIndexOf("/");
      if (lastIndex == -1) {
        console.debug(storeName, functionName, `Invalid story URL: '${url}'`);
        return null;
      } else {
        const storyId = url.substring(lastIndex + 1);
        console.debug(storeName, functionName, `StoryId: '${storyId}'`);
        return storyId;
      }
    },
  },
});
