<template>
  <div
    :style="{
      overflowY: 'auto',
      overflowX: 'hidden',
      height: `${containerHeight}px`,
    }"
    v-if="storiesSharedWithYouStore.stories.length > 0"
  >
    <v-row>
      <v-col
        v-for="story in storiesSharedWithYouStore.stories"
        :key="story.id"
        cols="12"
        style="margin-top: 10px"
      >
        <v-list-item class="d-flex justify-center">
          <v-card
            class="mx-auto flex-container"
            variant="tonal"
            :style="{
              width: dimenStore.isMobile ? '100%' : '350px',
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }"
            @click="navigateToStory(story.id)"
          >
            <v-carousel
              class="flex-item"
              continuous
              cycle
              :hide-delimiters="true"
              :show-arrows="false"
              :style="{
                pointerEvents: 'none',
                width: dimenStore.isMobile ? `${windowWidth - 32}px` : '350px',
                height: dimenStore.isMobile ? '100%' : '250px',
              }"
            >
              <v-carousel-item
                v-for="storyPage in story.pages"
                :key="storyPage.id"
              >
                <div
                  :style="{
                    width: dimenStore.isMobile ? '100%' : '350px',
                    height: '250px',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }"
                >
                  <v-img
                    :src="storyPage.mediaUrl"
                    :class="
                      nsfwStore.nsfwMode == true
                        ? 'nsfw-image-on'
                        : 'nsfw-image-off'
                    "
                  >
                    <template v-slot:placeholder>
                      <div
                        class="d-flex align-center justify-center fill-height"
                      >
                        <v-progress-circular
                          color="grey-lighten-4"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                </div>
              </v-carousel-item>
            </v-carousel>
            <v-divider
              style="
                width: 95%;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 5px;
                margin-right: 5px;
              "
            ></v-divider>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              "
            >
              <v-card
                variant="tonal"
                style="
                  background-color: transparent;
                  margin-bottom: 5px;
                  max-width: 100%;
                  width: fit-content;
                "
              >
                <template v-slot:prepend>
                  <v-icon icon="mdi-account"></v-icon>
                </template>
                <template v-slot:append>
                  <p>{{ story.creatorName }}</p>
                </template>
              </v-card>
              <v-card variant="tonal" style="background-color: transparent">
                <template v-slot:prepend>
                  <v-icon icon="mdi-clock-time-eight-outline"></v-icon>
                </template>
                <template v-slot:append>
                  <p>{{ getTimeSince(story.createdAt) }}</p>
                </template>
              </v-card>
            </div>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
  <div
    v-else
    :style="{
      display: 'flex',
      height: `${containerHeight}px`,
      overflowY: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    }"
  >
    <h4 style="color: grey">
      {{ "No stories have been shared with you yet" }}
    </h4>
  </div>
  <!-- [start] FAB -->
  <div
    :style="{
      position: 'absolute',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      bottom: `${dimenStore.vBotNavHeight}px`,
      right: '0px',
      marginBottom: '5px',
      padding: '10px',
    }"
  >
    <v-btn
      :disabled="storiesSharedWithYouStore.allowNewStoryRequest == false"
      color="primary"
      icon="mdi-message-arrow-right"
      @click.stop="
        () => {
          dialogRequestStoryFromFriend = true;
        }
      "
    />
  </div>
  <!-- [end] FAB -->
  <!-- [start] v-dialog -->
  <v-dialog
    max-width="500"
    v-model="dialogRequestStoryFromFriend"
    transition="dialog-fade-transition"
  >
    <template v-slot:default="{ isActive }">
      <v-card title="Request story">
        <v-card-text>
          <p style="display: inline">{{ "Request a story from " }}</p>
          <p style="display: inline; font-weight: bold">
            {{ friendStore.getFriendUsername() }}
          </p>
          <p style="display: inline">?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            variant="tonal"
            @click="handleRequestStoryFromFriend"
            :disabled="processingRequestStoryFromFriend"
            ><v-icon
              v-if="processingRequestStoryFromFriend == false"
              icon="mdi-check"
            ></v-icon>
            <v-progress-circular v-else color="primary" indeterminate
          /></v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text="Close"
            variant="text"
            color="error"
            @click="isActive.value = false"
            :disabled="processingRequestStoryFromFriend"
            ><v-icon icon="mdi-close"></v-icon
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  <!-- [end] v-dialog -->
</template>
<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import { useRouter } from "vue-router";
import { useDimenStore } from "@/stores/dimenStore";
import { useStoriesSharedWithYou } from "@/stores/storiesSharedWithYouStore";
import { useCurrentViewedStoryStore } from "@/stores/currentViewedStoryStore";
import { useFriendStore } from "@/stores/friendStore";
import { useSnackBarStore } from "@/stores/snackBarStore";

// ZHhPKpfo -->
import { useNsfwStore } from "@/stores/nsfwStore";
// <--

import requestStoryFromFriend from "@/utils/story/requestStoryFromFriend";
import getTimeSince from "@/utils/getTimeSince";

const componentName = "PrivateSharedPosts";

export default {
  name: componentName,
  setup() {
    const router = useRouter();
    const dimenStore = useDimenStore();
    const storiesSharedWithYouStore = useStoriesSharedWithYou();
    const currentViewedStoryStore = useCurrentViewedStoryStore();
    const friendStore = useFriendStore();
    const snackBarStore = useSnackBarStore();

    // ZHhPKpfo -->
    const nsfwStore = useNsfwStore();
    // <--

    const containerHeight = ref(null);
    const windowWidth = ref(null);

    const dialogRequestStoryFromFriend = ref(false);
    const processingRequestStoryFromFriend = ref(false);

    const navigateToStory = (storyId) => {
      const story = storiesSharedWithYouStore.getStoryById(storyId);
      currentViewedStoryStore.setStory(story);

      router.push({
        name: "posts",
        params: {
          postId: storyId,
        },
      });
    };

    const handleRequestStoryFromFriend = async () => {
      const functionName = "handleRequestStoryFromFriend";
      console.info(functionName);

      processingRequestStoryFromFriend.value = true;

      const response = await requestStoryFromFriend();

      const message =
        response.status == 200
          ? `Requested a story from ${friendStore.getFriendUsername()}`
          : `Error occurred when requesting story from ${friendStore.getFriendUsername()}`;
      const color = response.status == 200 ? "success" : "error";
      const timeout = response.status == 200 ? 2250 : 2500;

      processingRequestStoryFromFriend.value = false;
      dialogRequestStoryFromFriend.value = false;

      snackBarStore.displayNotification({
        message: message,
        color: color,
        timeout: timeout,
      });

      // setTimeout(() => {
      //   processingRequestStoryFromFriend.value = false;
      //   dialogRequestStoryFromFriend.value = false;

      //   snackBarStore.displayNotification({
      //     message: message,
      //     color: color,
      //     timeout: timeout,
      //   });
      // }, 1000);

      // processingRequestStoryFromFriend.value = false;
    };

    onMounted(() => {
      const functionName = "onMounted";
      console.info(componentName, functionName);

      windowWidth.value = window.innerWidth;

      dimenStore.calculateIsMobile();
      dimenStore.calculateTopNavHeight();
      dimenStore.calculateBottomNavHeight();

      containerHeight.value = dimenStore.calculateContainerHeight(true, true);

      window.addEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(true, true);
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(true, true);
      });
    });

    return {
      containerHeight,
      windowWidth,
      dimenStore,
      storiesSharedWithYouStore,
      getTimeSince,
      navigateToStory,
      dialogRequestStoryFromFriend,
      friendStore,
      requestStoryFromFriend,
      handleRequestStoryFromFriend,
      processingRequestStoryFromFriend,
      // ZHhPKpfo
      nsfwStore,
    };
  },
};
</script>

<style scoped>
.flex-container {
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Add space between items */
  align-items: center;
}

.flex-item {
  width: 50%; /* Set the width of each item */
  /* Add other styles as needed */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .flex-container {
    flex-direction: column; /* Change to column layout on smaller screens */
  }

  .flex-item {
    width: 50%; /* Full width for items on smaller screens */
  }
}

/* ZHhPKpfo --> */
.nsfw-image-on {
  filter: blur(10px);
  will-change: filter; /* May fix glitching issues when blurring media on Safari iOS */
  transition: filter 0.3s ease;
}

.nsfw-image-off {
  filter: blur(0px);
  transition: filter 0.3s ease;
}
/* <-- */
</style>
